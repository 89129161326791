import React from 'react';
import { useTranslation } from 'react-i18next';

function GreenPass(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  const { t } = useTranslation();

  return (
    <>
      <svg
        aria-label={t('icons.passed')}
        height='50'
        viewBox='0 0 200 200'
        width='50'
        xmlns='http://www.w3.org/2000/svg'
        {...props}
      >
      <g>
        <title>{t('icons.passed')}</title>
        <circle
          cx='100'
          cy='100'
          fill='#007bff'
          r='90'
          stroke='var(--primary-color)'
          strokeDasharray='null'
        />
        <rect
          fill='var(--primary-background)'
          height='15'
          stroke='var(--primary-background)'
          strokeDasharray='null'
          transform='rotate(-45, 120, 106.321)'
          width='128.85878'
          x='73.57059'
          y='91.32089'
        />
        <rect
          fill='var(--primary-background)'
          height='15'
          stroke='var(--primary-background)'
          strokeDasharray='null'
          transform='rotate(45, 66.75, 123.75)'
          width='63.66548'
          x='26.41726'
          y='108.75'
        />
      </g>
      </svg>
    </>
  );
}

GreenPass.displayName = 'GreenPass';

export default GreenPass;
