import React, { useEffect, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { completedChallengesSelector } from '../../redux';
import { makeExpandedBlockSelector } from './redux';

const Curriculum = (props: any): JSX.Element => {
  const { t } = useTranslation();
  const {
    defaultCurriculumNames,
    nodesForSuperBlock,
    superBlock,
    completedChallenges
  } = props;
  const superBlockIntroObj: {
    title: string;
    intro: string[];
    note: string[];
    blocks: any;
  } = t(`intro:${superBlock}`);

  const [isSticky, setSticky] = useState(true);
  const ref: any = useRef(null);
  const ref_w: any = useRef(null);
  const handleScroll = () => {
    if (ref_w.current && ref.current) {
      const scrollPosition = window.pageYOffset; // Current vertical scroll position
      const windowHeight = window.innerHeight; // Height of the window
      const docHeight = document.documentElement.scrollHeight; // Total document height

      // Calculate the scroll percentage
      const scrollPercent = (scrollPosition + windowHeight) / docHeight;

      // If the scroll percentage is greater than or equal to 0.8 (80%), hide the div
      if (scrollPercent >= 0.9) {
        ref.current.style.display = 'none';
      } else {
        // Else, make sure the div is visible
        ref.current.style.display = 'block';
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', () => handleScroll);
    };
  }, []);

  const currentChallenge = nodesForSuperBlock.find((node: any) => completedChallenges.indexOf(node.challenge.id) == -1);

  const chapterClicked = function(node: any, locked: any) {
    // if(!locked) {
      location.href = node.challenge.fields.slug;
    // } else {
    //   console.log(lesson.node.challenge.fields.slug);
    // }
  }

  return (
    <>
      <Helmet>

        <link rel="shortcut icon" href="/assets/images/logo/favourite_icon_1.png" />

        <link rel="stylesheet" type="text/css" href="/assets/css/bootstrap.min.css" />

        <link rel="stylesheet" type="text/css" href="/assets/css/icomoon.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/themify-icons.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/fontawesome-all.css" />

        <link rel="stylesheet" type="text/css" href="/assets/css/owl.carousel.min.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/owl.theme.default.min.css" />

        <link rel="stylesheet" type="text/css" href="/assets/css/aos.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/animate.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/splitting.css" />

        <link rel="stylesheet" type="text/css" href="/assets/css/magnific-popup.css" />

        <link rel="stylesheet" type="text/css" href="/assets/css/style.css" />
        <link rel="stylesheet" type="text/css" href="/assets/css/_extraise.css" />
      </Helmet>
      <section className="details_section faq_section sec_ptb_120 bg_gray clearfix">
        <div className={`container sticky-wrapper ${isSticky ? 'sticky' : ''}`} ref={ref_w}>
          <div className="row justify-content-lg-between justify-content-md-center justify-content-sm-center">
            <div className="col-lg-4 col-md-9 col-sm-10 order-last">
              <div className="job_details_info ul_li_block sticky-inner" data-aos="fade-up" data-aos-delay={400} ref={ref}>
                <ul className="clearfix">
                  <li>
                    <div className="item_icon">
                      <i className="ti-write" style={{ color: "#007bff", fontWeight: 900 }} />
                    </div>
                    <div className="item_contact">
                      <h4 style={{ marginTop: '7px' }}>{nodesForSuperBlock.length} Chapters</h4>
                    </div>
                  </li>
                  {/* <li>
                    <div className="item_icon">
                      <i className="ti-timer" style={{ color: "#007bff", fontWeight: 900 }} />
                    </div>
                    <div className="item_contact">
                      <h4 style={{ marginTop: '7px' }}>{Math.round(nodesForSuperBlock.length / 60)} Hours to complete</h4>
                    </div>
                  </li> */}
                  <li>
                    <div className="item_icon" style={{ marginTop: "-3px" }}>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="#007bff" xmlns="http://www.w3.org/2000/svg" className="text-primary dark:text-primary-lightP1 fill-current"><path fillRule="evenodd" clipRule="evenodd" d="M10 1.7a5 5 0 100 10 5 5 0 000-10zm-6.7 5a6.7 6.7 0 1113.4 0 6.7 6.7 0 01-13.4 0z" fill="current"></path><path fillRule="evenodd" clipRule="evenodd" d="M13 10.7c.5 0 1 .3 1 .8l1 7.6a.8.8 0 01-1.3.8L10 17.6 6.3 20A.8.8 0 015 19l1-7.6a.8.8 0 011.7.2l-.8 5.9L9.6 16c.2-.2.6-.2.8 0l2.7 1.6-.8-6c0-.4.3-.8.7-.9z" fill="current"></path></svg>
                    </div>
                    <div className="item_contact">
                      <h4 style={{ marginTop: '7px' }}>Certificate on completion</h4>
                    </div>
                  </li>
                </ul>
                <a className='btn bg_default_blue' href={ currentChallenge.challenge.fields.slug } style={{ borderRadius: 0, color: "#fff", width: "100%", marginTop: "30px" }}>Start Learning</a>
              </div>
            </div>
            <div className="col-lg-8 col-md-9 col-sm-10 chield_item">
              {/* <div className="item_content">
                <div data-aos="fade-up" data-aos-delay={300}>
                  <h3 className="title_text mb-30">{superBlockIntroObj.title}</h3>
                  <div className="info_list ul_li_block mb-50">
                    {superBlockIntroObj.intro.map((para: any, index: any) => (
                      <p key={index}>{para}</p>
                    ))}
                  </div>
                </div>
              </div> */}
              <div className="item_content">
                <div data-aos="fade-up" data-aos-delay={300}>
                  <h3 className="title_text mb-30">Takeaway Skills</h3>
                  <div className="info_list ul_li_block mb-50">
                    <ul className="clearfix mb-50">
                      {defaultCurriculumNames.map((blockDashedName: any, index: any) => (
                        <li key={index}>{superBlockIntroObj.blocks[blockDashedName].title}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                {/* <div id="license" className="mb-80" data-aos="fade-up" data-aos-delay={600}> */}
                <div id="license" className="mb-80">
                  <h3 className="title_text mb-50">Course Content</h3>
                  <div id="accordion_license" className="faq_accordion">
                    {defaultCurriculumNames.map((blockDashedName: any, index: any) => (
                      blockDashedName.indexOf("projects") > -1 ? null :
                      <div className="card" key={index}>
                        <div className="card-header">
                          <h6 className="">
                            {(index + 1)}. &nbsp;{blockDashedName.split("-").join(" ").toUpperCase()}
                          </h6>
                        </div>
                        <div id={"collapse_" + index} className="collapse show" data-parent="#accordion_license">
                          <div className="card-body chapters">
                            <ul className="clearfix">
                              { nodesForSuperBlock.map((node: any, _index: any) => {
                                let preview = false;
                                if(_index == 0 || blockDashedName != nodesForSuperBlock[_index-1].challenge.block) {
                                  preview = true;
                                }
                                if(node.challenge.block === blockDashedName) {
                                  const locked = !(preview || completedChallenges.indexOf(node.challenge.id) != -1 || completedChallenges < 20);
                                  return <li key={_index}>
                                    <a className={ locked ? 'locked' : '' } onClick={ () => chapterClicked(node, locked) }>
                                      { !locked && <>{ completedChallenges.findIndex((c: any) => c == node.challenge.id) != -1 ?
                                        <svg height={25} viewBox="0 0 200 200" width={25} xmlns="http://www.w3.org/2000/svg" style={{height: '18px', marginRight: '20px', width: '24px'}}><g><title>Passed</title><circle cx={100} cy={100} fill="#007bff" r={90} strokeDasharray="null" /><rect fill="var(--primary-background)" height={15} stroke="var(--primary-background)" strokeDasharray="null" transform="rotate(-45, 120, 106.321)" width="128.85878" x="73.57059" y="91.32089" /><rect fill="var(--primary-background)" height={15} stroke="var(--primary-background)" strokeDasharray="null" transform="rotate(45, 66.75, 123.75)" width="63.66548" x="26.41726" y="108.75" /></g></svg> : <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" className="dark:text-dark-contrastText fill-current"><path d="M0 0h24v24H0V0z" fill="none"></path><path d="M12 6a6 6 0 110 12 6 6 0 010-12m0-2a8 8 0 100 16 8 8 0 000-16z"></path></svg>
                                      }</> }
                                      { locked && <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" className="dark:text-dark-contrastText fill-current"><path d="M0 0h24v24H0z" fill="none"></path><path d="M18 8h-1V6A5 5 0 007 6v2H6a2 2 0 00-2 2v10c0 1.1.9 2 2 2h12a2 2 0 002-2V10a2 2 0 00-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6a3.1 3.1 0 016.2 0v2z"></path></svg>}
                                      <span>{node.challenge.title}</span>
                                      { preview && <div style={{ border: "2px solid #333",lineHeight: "25px",padding: "0 11px",fontSize: "14px" }}>Preview</div>}
                                    </a>
                                  </li>
                                } else {
                                  return null;
                                }
                              }) }
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  const expandedSelector = makeExpandedBlockSelector(ownProps.blockDashedName);
  return createSelector(
    expandedSelector,
    completedChallengesSelector,
    (isExpanded: any, completedChallenges: any) => ({
      isExpanded,
      completedChallenges: completedChallenges.map(({ id }: any) => id)
    })
  )(state);
};

Curriculum.displayName = 'Curriculum';

export default connect(mapStateToProps)(Curriculum);
